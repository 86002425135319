import React from 'react'

import './footer.css'

const Footer = () => (
  <footer style={{
    'width': '100%'
  }}>
    <section>
    </section>
  </footer>
)

export default Footer
