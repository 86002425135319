import React from 'react'

import './header.css'

const Header = ({ siteTitle }) => (
  <header>
  </header>
)

export default Header
